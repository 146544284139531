<template>
  <el-header
    class="
      border-bottom
      header
      bg-primary-dark
      text-white
      items-center
      flex
      fixed
      inset-x-0
      top-0
      z-50
    "
  >
    <button
      :class="[
        'text-white hover:bg-primary-light w-10 h-10 flex items-center justify-center rounded-full bg-primary nav-toggle',
        { 'nav-toggle-on': navCollapse },
      ]"
      @click="toggleNavCollapse"
    >
      <svg
        class="fill-current w-6 h-6"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <g>
          <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
        </g>
      </svg>
    </button>
    <div class="ml-32">
      <router-link to="/">
        <h1 class="text-base ml-8 text-white">麦芽儿门店管理</h1>
      </router-link>
    </div>
    <div class="flex-grow" />

    <el-dropdown class="flex mr-8 relative">
      <img class="h-12 object-contain logo" src="@/assets/maiya.jpg" />
      <el-badge
        v-if="unread_message_count"
        :value="unread_message_count"
        class="absolute top-0 -right-3"
      >
      </el-badge>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <router-link :to="{ name: 'message' }">
            <div class="flex px-4 text-base items-center w-40">
              <span> 查看消息 </span>

              <div class="flex-grow" />
              <el-badge
                v-if="unread_message_count"
                :value="unread_message_count"
                class="mt-3"
              >
              </el-badge>
            </div>
          </router-link>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dropdown v-if="user" trigger="click" @command="handleMenuCommand">
      <span class="el-dropdown-link text-white">
        {{ user.name }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="logout">登出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </el-header>
</template>
<script>
import * as Sentry from '@sentry/browser'
import store from '@/store'
import { mapState } from 'vuex'
export default {
  name: 'Header',
  props: {
    navCollapse: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      store,
    }
  },
  computed: {
    ...mapState(['user', 'unread_message_count']),
  },
  mounted() {
    this.store.dispatch('getUnreadMessageCount')
    this.interval = setInterval(() => {
      this.store.dispatch('getUnreadMessageCount')
    }, 1000 * 10)
  },
  unmounted() {
    clearInterval(this.interval)
  },

  methods: {
    toggleNavCollapse() {
      this.$emit('changeNavCollapse')
    },
    handleMenuCommand(command) {
      switch (command) {
        case 'logout':
          this.logout()
          break
        default:
          throw '没有该命令'
      }
    },
    logout: function () {
      localStorage.clear()
      Sentry.setUser(null)
      this.$router.push('/login')
    },
  },
}
</script>
<style lang="scss" scoped>
.nav-toggle {
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.nav-toggle-on {
  transform: rotate(-180deg);
}
.logo {
  border-radius: 12px;
  transition: transform 0.1s cubic-bezier(0, 1.8, 1, 1.8);
}
.logo:hover {
  /* transform: translateY(5px) rotate(-2deg) scale(1.15); */
}
</style>
